import ClaimTree from '../assets/tree.json'

export interface ClaimInfo {
  index: number
  account: string
  amount: string
  proof: string[]
}

interface MerkleDistributorInfo {
  merkleRoot: string
  claims: ClaimInfo[]
}

export function getClaimInfo(account: any): ClaimInfo[] | undefined | null {
  let claimTree: MerkleDistributorInfo = ClaimTree;

  if (!account) return null;

  account = account.toLowerCase();

  let claims: ClaimInfo[] = claimTree.claims.filter(item => {
    return item.account === account;
  })

  if (claims.length > 0) {
    return claims;
  }

  return null;
}
