import { useSingleCallResult } from '../state/multicall/hooks'
import { useAirdropContract } from './useContract'
import { ClaimInfo, getClaimInfo } from 'utils/getClaimInfo'

export enum ClaimCallbackState {
  INVALID,
  LOADING,
  VALID
}


export function useClaimInfo(account: string | null | undefined): ClaimInfo | null {

  let claimInfo: ClaimInfo | null = null;
  const claimInfos = getClaimInfo(account)
  const airdropContract = useAirdropContract()

  const isClaimedAResult = useSingleCallResult(airdropContract, 'isClaimed', [(claimInfos && claimInfos.length > 0) ? claimInfos[0].index : undefined])
  const isClaimedA = Boolean(!isClaimedAResult.loading && isClaimedAResult.result?.[0] === true)

  const isClaimedBResult = useSingleCallResult(airdropContract, 'isClaimed', [(claimInfos && claimInfos.length > 1) ? claimInfos[1].index : undefined])
  const isClaimedB = Boolean(!isClaimedBResult.loading && isClaimedBResult.result?.[0] === true)
  console.log(isClaimedA, isClaimedB, claimInfos);

  if (claimInfos) {
    if (!isClaimedA) {
      claimInfo = claimInfos[0];
    }
    else if (claimInfos.length > 1 && !isClaimedB) {
      claimInfo = claimInfos[1];
    }
  }

  return claimInfo;

}